<template>
  <b-card class="ticket-create-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Create New Ticket
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="ticketFormValidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="submitTicket"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Subject"
              label-for="ticket-subject"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Subject" rules="required">
                <b-form-input
                  id="ticket-subject"
                  v-model="ticketForm.subject"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="Category ID"
              label-for="ticket-category-id"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Category ID" rules="required">
                <v-select
                  id="blog-edit-category"
                  v-model="ticketForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="User Name"
              label-for="ticket-subject"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Subject" rules="required">
                <b-form-input
                  id="ticket-subject"
                  v-model="ticketForm.user_name_on_client"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Body"
              label-for="ticket-body"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Body" rules="required">
               
                <quill-editor
                  ref="dayContentFormEditor"
                 v-model="ticketForm.body"
                  :options="getEditorOptions('dayContentFormEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Attachments"
              label-for="ticket-attachments"
              class="mb-2"
            >
              <b-form-file
                id="ticket-attachments"
                @change="handleFileUpload"
                multiple
              />
              <ul>
                <li v-for="file in uploadedFiles" :key="file.id">
                  {{ file.name }}
                </li>
              </ul>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="Status"
              label-for="ticket-status"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Status">
              
                <v-select
                  id="blog-s-category"
                    v-model="ticketForm.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Open','Closed','Pending','Spam','Locked']"
                
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group
              label="Created by Agent"
              label-for="ticket-created-by-agent"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Created by Agent">
                <b-form-checkbox
                  id="ticket-created-by-agent"
                  v-model="ticketForm.created_by_agent"
                >
                  Yes
                </b-form-checkbox>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="submitTicket"
            >
              Submit Ticket
            </b-button>
            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'

// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  components: {
    BCard,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
     quillEditor,
    BFormFile
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const ticketForm = ref({
      user_id: '',
      subject: '',
      category_id: '',
      user_name_on_client:"",
      body: '',
      attachments: [],
      status: 'Open',
      created_by_agent: true
    })

    const categoriesList = ref([])
    // store.dispatch('tickets/AllCategories').then(response => {
    //   console.log(response)
    //   categoriesList.value = response.pagination.data
    // })

    const ticketFormValidate = ref(null)
    const form = ref(null)
    const uploadedFiles = ref([])

    const handleFileUpload = async (event) => {
      const files = event.target.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const formData = new FormData()
        formData.append('file', file)
 const token = localStorage.getItem('SupportToken')
        try {
          const response = await axios.post('https://support.prokoders.work/client/file-entries', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${token}`,
            }
          })
          console.log(response)
          uploadedFiles.value.push({ id: response.data.fileEntry.id, name: file.name })
          ticketForm.value.attachments.push(response.data.fileEntry.id)
        } catch (error) {
          console.error('Error uploading file:', error)
        }
      }
    }

    const submitTicket = () => {
      ticketFormValidate.value.validate().then(success => {
        if (success) {
          isLoading.value = true
          let data = {
            user_id: 12,
            subject: ticketForm.value.subject,
            category_id: 7,
            body: ticketForm.value.body,
            attachments: ticketForm.value.attachments,
            status: ticketForm.value.status,
            created_by_agent: ticketForm.value.created_by_agent,
            user_name_on_client:ticketForm.value.user_name_on_client
          }
          store
            .dispatch('tickets/addTickets', data)
            .then(response => {
              isLoading.value = false
             ticketForm.value={
                user_id: '',
                user_name_on_client:"",
      subject: '',
      category_id: '',
      body: '',
      attachments: [],
      status: 'Open',
      created_by_agent: true
             }
             emit('add-category')
              Vue.swal({
                title: 'Ticket Created',
                text: 'Your ticket has been created successfully.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            })
            .catch(error => {
              isLoading.value = false
              console.error('Error creating ticket', error)
              Vue.swal({
                title: 'Error',
                text: 'There was an error creating your ticket. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            })
        }
      })
    }
    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph'],

          ],
        
        },
      },
    });

    return {
      getEditorOptions,
      isLoading,
      ticketForm,
      ticketFormValidate,
      form,
      submitTicket,
      categoriesList,
      handleFileUpload,
      uploadedFiles
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.ticket-create-wrapper {
  padding: 2rem;
}
</style>
